'use strict';

/**
 * Show more items.
 */
app.viewMore = {
	_vars: {},

	/**
	 * Make base initialization with parameters.
	 * @param {Object} params - The parameters.
	 * @param {string} params.list - The list selector.
	 * @param {string} params.btn - The button selector.
	 */
	init: function (params) {
		if (!params || !$(params.list).length || !$(params.btn).length) {
			return false;
		}

		var that = this;
		params.liCount = $(params.list).children().length;
		var itemsInRow = computeItemsPerRow();
		if (itemsInRow > '1') {
			params.prodSize = Number(itemsInRow * 2);
			params.loadSize = Number(itemsInRow);
		} else {
			params.prodSize = 2;
			params.loadSize = 2;
		}

		that.hideButton(params);

		params.listItems = params.list + ' .' + $(params.list).children().attr('class').split(' ')[0];
		$(params.listItems).css({'display': 'none', 'opacity': '0'});
		$(params.listItems + ':lt(' + params.prodSize + ')').css({'display': 'flex', 'opacity': '1'});

		$(params.btn).click(function () {
			that.view(params);
		});

		/**
		 * Returns amount the items in row
		 * @return {string}
		 */
		function computeItemsPerRow() {
			var liWidth = $(params.list).children().width();
			var rowWidth = $(params.list).width();
			var itemsInRow = rowWidth / liWidth;
			return itemsInRow.toFixed();
		}
	},

	/**
	 * View More.
	 * @param {Object} params.
	 */
	view: function (params) {
		params.prodSize = (params.prodSize + params.loadSize <= params.liCount) ? params.prodSize + params.loadSize : params.liCount;
		$(params.listItems + ':lt(' + params.prodSize + ')').fadeIn('fast', function () {
			$(this).css('opacity', '1');
		});
		this.hideButton(params);
	},

	/**
	 * Hide button.
	 * @param {Object} params.
	 * @returns {boolean}
	 */
	hideButton: function (params) {
		if (params.liCount <= params.prodSize) {
			$(params.btn).hide();
			return false;
		}
	}
};